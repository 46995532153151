<template>
  <div>
    <el-pagination
    style="text-align: center"
    @current-change="handleCurrentChange"
    hide-on-single-page
    background
    @size-change="sizeChange"
    :current-page="page"
    :page-sizes="[10, 20,50,100,1000,5000,99999]"
    :page-size="limit"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
  >
  </el-pagination>
  <div v-if="total <= 50" class="text-center" style="color: #999">
    共{{total}}条
  </div>
  </div>
</template>

<script>
export default {
    props: {
     limit: {
        default:10
     },
     page: {
        default: 1
     },
     total: {
        default: 0
     }
    },
    data() {
        return {

        }
    },
    methods: {
    handleCurrentChange(currentPage) {
            this.$emit('pageChange',currentPage)
        },
    sizeChange(size) {
      this.$emit('sizeChange',size)
    },
    }
}
</script>

<style>
</style>