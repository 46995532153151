  import request from '@/utils/request'
  //封装获取工具列表的接口
export const getToolsList = (params) => {
  return request({
      url:'/tools/toolslist',
      method:'GET',
      params
  })
}
// 封装获取工具历史记录的接口
export const getToolHistory = (params) => {
  return request({
      url:'/tools/history',
      method:'GET',
      params
  })
}
// 封装获取请求条件的接口
export const toolsListSelection = (params) => {
  return request({
      url:'/tools/toolslist/selection',
      method:'GET',
      params
  })
}
// 封装新增工具的接口
export const addTools = (data)=>{
  return request({
      url: '/tools/toolslist/addtools',
      method:'POST',
      data
  })
}
// 封装编辑工具的接口
export const editTools = (data)=>{
  return request({
      url: '/tools/toolslist/changetools',
      method:'POST',
      data
  })
} 
// 封装获取环境列表的接口
export const getEnvList = (params) => {
  return request({
      url:'/tools/env/envlist',
      method:'GET',
      params
  })
}
// 封装新增环境的接口
export const addEnv = (data) => {
  return request({
      url:'/tools/env/addenv',
      method:'POST',
      data
  })
}
// 封装修改环境的接口
export const changeEnv = (data) => {
  return request({
      url:'/tools/env/changeenv',
      method:'POST',
      data
  })
}
// 封装删除环境的接口
export const delateEnv = (data) => {
  return request({
      url:'/tools/env/delateenv',
      method:'POST',
      data
  })
}
// 封装获取数据的接口  
export const getAnalysis = (params) => {
  return request({
    url: 'tools/analysis',
    params
  })
}
// 封装删除工具的接口  
export const deleteTool = (data) => {
  return request({
    url: 'tools/toolslist/delatetools',
    method:'POST',
    data
  })
}
// 封装各种环境配置工具的接口
export const getAllTools = () => {
  return request({
    url: 'tools/tools_analysis',
    method: 'GET'
  })
}
// 封装调整工具优先级的接口
export const change_tool_priority = (params) => {
  return request({
    url: 'tools/change_priority',
    method: 'GET',
    params
  })
}
// 封装修改分类优先级的接口
export const change_cla_priority = (params) => {
  return request({
    url: 'tools/change_classification_priority',
    method: 'GET',
    params
  })
}
// 封装修改工具分类的接口
export const change_tool_cla = (data) => {
  return request({
    url: 'tools/change_classification',
    method: 'POST',
    data
  })
}
// 封装获取工具分类的接口
export const getClassification = () => {
  return request({
    url: 'tools/get_classification' ,
    method: "GET",
  })
}
// 封装修改工具分类别名的接口
export const change_alias= (classification,alias) => {
  return request({
    url: 'tools/change_alias' ,
    method: "GET",
    params: {
      classification,
      alias
    }
  })
}