var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"menu_container"},[_c('el-menu',{staticStyle:{"margin-top":"50px"},attrs:{"collapse":_vm.isCollapse,"unique-opened":true}},[_c('el-submenu',{staticClass:"title",attrs:{"index":"1"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-s-home",on:{"click":function($event){return _vm.$router.push('/')}}}),_vm._v("首页")]),_c('el-menu-item-group',[_c('el-menu-item',{attrs:{"index":"1-1"},on:{"click":function($event){return _vm.$router.push({ name: 'ana', params: { title: '数据分析' } })}}},[_vm._v("数据分析")]),_c('el-menu-item',{attrs:{"index":"1-2"},on:{"click":function($event){return _vm.$router.push({ name: 'account_balance', params: { title: '首页' } })}}},[_vm._v("第三方账号余额")]),_c('el-menu-item',{attrs:{"index":"9-2"},on:{"click":function($event){return _vm.$router.push({
                name: 'chat_page',
                params: { title: '首页' },
              })}}},[_vm._v("智能客服")])],1)],2),_c('el-submenu',{staticClass:"title",attrs:{"index":"11"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-eleme"}),_vm._v("号码标记")]),_c('el-menu-item-group',[_c('el-menu-item',{attrs:{"index":"11-1"},on:{"click":function($event){return _vm.$router.push({ name: 'BiaojiAna', params: { title: '号码标记' } })}}},[_vm._v("数据分析")]),_c('el-menu-item',{attrs:{"index":"11-2"},on:{"click":function($event){return _vm.$router.push({ name: 'BiaojiUser', params: { title: '号码标记' } })}}},[_vm._v("渠道记录")]),_c('el-menu-item',{attrs:{"index":"11-3"},on:{"click":function($event){return _vm.$router.push({ name: 'BiaojiPhone', params: { title: '号码标记' } })}}},[_vm._v("号码记录")])],1)],2),_c('el-submenu',{attrs:{"index":"2"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-user"}),_vm._v("用户管理")]),_c('el-menu-item-group',[_c('el-menu-item',{attrs:{"index":"2-1"},on:{"click":function($event){return _vm.$router.push({ name: 'user_list', params: { title: '用户管理' } })}}},[_vm._v("用户列表")]),_c('el-menu-item',{attrs:{"index":"2-2"},on:{"click":function($event){return _vm.$router.push({
              name: 'blackUser_list',
              params: { title: '用户管理' },
            })}}},[_vm._v("黑名单列表")]),_c('el-menu-item',{attrs:{"index":"2-3"},on:{"click":function($event){return _vm.$router.push({
              name: 'whiteUser_list',
              params: { title: '用户管理' },
            })}}},[_vm._v("白名单列表")]),_c('el-menu-item',{attrs:{"index":"2-6"},on:{"click":function($event){return _vm.$router.push({
              name: 'user_analysis',
              params: { title: '用户管理' },
            })}}},[_vm._v("用户数据")]),_c('el-menu-item',{attrs:{"index":"2-7"},on:{"click":function($event){return _vm.$router.push({
                name: 'user_feedback',
                params: { title: '用户管理' },
              })}}},[_vm._v("用户反馈列表")]),_c('el-menu-item',{attrs:{"index":"2-8"},on:{"click":function($event){return _vm.$router.push({
                name: 'user_complaint',
                params: { title: '用户管理' },
              })}}},[_vm._v("用户投诉列表")])],1)],2),_c('el-submenu',{attrs:{"index":"3"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-s-order"}),_vm._v("订单管理")]),_c('el-menu-item-group',[_c('el-menu-item',{attrs:{"index":"3-1"},on:{"click":function($event){return _vm.$router.push({
              name: 'order_list',
              params: { title: '订单管理' },
            })}}},[_vm._v("订单列表")]),_c('el-menu-item',{attrs:{"index":"3-3"},on:{"click":function($event){return _vm.$router.push({
              name: 'order_analysis',
              params: { title: '订单管理' },
            })}}},[_vm._v("订单数据")])],1)],2),_c('el-submenu',{attrs:{"index":"4"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-s-tools"}),_vm._v("工具管理")]),_c('el-menu-item-group',[_c('el-menu-item',{attrs:{"index":"4-1"},on:{"click":function($event){return _vm.$router.push({ name: 'tools', params: { title: '工具管理' } })}}},[_vm._v("工具列表大纲")]),_c('el-menu-item',{attrs:{"index":"4-6"},on:{"click":function($event){return _vm.$router.push({
              name: 'tools_list',
              params: { title: '工具管理' },
            })}}},[_vm._v("工具列表")]),_c('el-menu-item',{attrs:{"index":"4-2"},on:{"click":function($event){return _vm.$router.push({
              name: 'tools_history',
              params: { title: '工具管理' },
            })}}},[_vm._v("工具使用历史记录")]),_c('el-menu-item',{attrs:{"index":"4-3"},on:{"click":function($event){return _vm.$router.push({ name: 'tools_env', params: { title: '工具管理' } })}}},[_vm._v("环境对应管理")]),_c('el-menu-item',{attrs:{"index":"4-4"},on:{"click":function($event){return _vm.$router.push({
              name: 'tools_analysis',
              params: { title: '工具管理' },
            })}}},[_vm._v("数据详情")]),_c('el-menu-item',{attrs:{"index":"4-5"},on:{"click":function($event){return _vm.$router.push({
              name: 'tools_change_cla',
              params: { title: '工具管理' },
            })}}},[_vm._v("分类管理")])],1)],2),_c('el-submenu',{attrs:{"index":"5"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-menu"}),_vm._v("数据配置")]),_c('el-menu-item-group',[_c('el-menu-item',{attrs:{"index":"5-1"},on:{"click":function($event){return _vm.$router.push({
              name: 'systemAdmin',
              params: { title: '数据配置' },
            })}}},[_vm._v("登录历史")]),_c('el-menu-item',{attrs:{"index":"5-3"},on:{"click":function($event){return _vm.$router.push({
              name: 'projectAdmin',
              params: { title: '数据配置' },
            })}}},[_vm._v("json项目配置")]),_c('el-menu-item',{attrs:{"index":"5-4"},on:{"click":function($event){return _vm.$router.push({
                name: 'page_pay_way',
                params: { title: '数据配置' },
              })}}},[_vm._v("付款方式配置")]),_c('el-menu-item',{attrs:{"index":"5-5"},on:{"click":function($event){return _vm.$router.push({
                name: 'page_problem',
                params: { title: '数据配置' },
              })}}},[_vm._v("常见问题管理")]),_c('el-menu-item',{attrs:{"index":"9-1"},on:{"click":function($event){return _vm.$router.push({
                name: 'chat_rule',
                params: { title: '数据配置' },
              })}}},[_vm._v("智能客服回复规则")])],1)],2),_c('el-submenu',{attrs:{"index":"7"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-document"}),_vm._v("废弃")]),_c('el-menu-item-group',[_c('el-menu-item',{attrs:{"index":"7-1"},on:{"click":function($event){return _vm.$router.push({
              name: 'page_function',
              params: { title: '页面配置' },
            })}}},[_vm._v("页面功能管理")]),_c('el-menu-item',{attrs:{"index":"7-3"},on:{"click":function($event){return _vm.$router.push({
              name: 'page_urlScheme',
              params: { title: '页面配置' },
            })}}},[_vm._v("urlScheme配置")]),_c('el-menu-item',{attrs:{"index":"7-4"},on:{"click":function($event){return _vm.$router.push({
              name: 'page_swiper',
              params: { title: '页面配置' },
            })}}},[_vm._v("轮播图配置")])],1)],2),_c('el-submenu',{attrs:{"index":"8"}},[_c('template',{slot:"title"},[_c('img',{staticClass:"img",attrs:{"src":require("../../assets/钱.svg")}}),_vm._v("营收管理")]),_c('el-menu-item-group',[_c('el-menu-item',{attrs:{"index":"8-2"},on:{"click":function($event){return _vm.$router.push({
              name: 'daily_data',
              params: { title: '营收管理' },
            })}}},[_vm._v("收支情况")]),_c('el-menu-item',{attrs:{"index":"8-3"},on:{"click":function($event){return _vm.$router.push({
              name: 'daily_data_relationship',
              params: { title: '营收管理' },
            })}}},[_vm._v("各推广关系收支情况")]),_c('el-menu-item',{attrs:{"index":"8-4"},on:{"click":function($event){return _vm.$router.push({
              name: 'income_relationship',
              params: { title: '营收管理' },
            })}}},[_vm._v("推广关系管理")]),_c('el-menu-item',{attrs:{"index":"8-5"},on:{"click":function($event){return _vm.$router.push({name: 'queryword_report', params: { title: '营收管理' },})}}},[_vm._v(" 搜索词报告 ")]),_c('el-menu-item',{attrs:{"index":"8-6"},on:{"click":function($event){return _vm.$router.push({name: 'keyword_report', params: { title: '营收管理' },})}}},[_vm._v(" 关键词报告 ")]),_c('el-menu-item',{attrs:{"index":"8-7"},on:{"click":function($event){return _vm.$router.push({name: 'get_operate_log', params: { title: '营收管理' },})}}},[_vm._v(" 广告报告操作记录 ")])],1)],2),_c('el-submenu',{attrs:{"index":"10"}},[_c('template',{slot:"title"},[_c('i',{staticClass:"el-icon-files"}),_vm._v("管理端通用配置")]),_c('el-menu-item-group',[_c('el-menu-item',{attrs:{"index":"10-02"},on:{"click":function($event){return _vm.$router.push({
              name: 'sample_setting',
              params: { title: 'simple通用配置' },
            })}}},[_vm._v("simple通用配置")]),_c('el-menu-item',{attrs:{"index":"10-01"},on:{"click":function($event){return _vm.$router.push({
              name: 'general_config_json',
              params: { title: 'json通用配置' },
            })}}},[_vm._v("json通用配置")]),_c('el-menu-item',{attrs:{"index":"10-1"},on:{"click":function($event){return _vm.$router.push({
              name: 'file_manage',
              params: { title: '其他' },
            })}}},[_vm._v("文件管理")]),_c('el-menu-item',{attrs:{"index":"10-2"},on:{"click":function($event){return _vm.$router.push({
              name: 'short_link',
              params: { title: '其他' },
            })}}},[_vm._v("短链接管理")]),_c('el-menu-item',{attrs:{"index":"10-3"},on:{"click":function($event){return _vm.$router.push({
              name: 'message_promotion',
              params: { title: '其他' },
            })}}},[_vm._v("短信推广管理")])],1)],2),_c('div',[_c('div',{staticClass:"ban",staticStyle:{"text-align":"center"}},[_vm._v("v1.2.7")])]),_c('div',{staticClass:"fl_cen"},[_c('div',{staticClass:"fold_btn",on:{"click":_vm.toggleSidbar}},[_c('i',{staticClass:"el-icon-s-fold icon_fold pointer"})])])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }