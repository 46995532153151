import Vue from 'vue'
import Vuex from 'vuex'
import { getToken, setToken, removeToken } from '@/utils/auth'
import { login } from '@/api/login'
import router from '@/router'


Vue.use(Vuex)
export default new Vuex.Store({
  state: {
    token: getToken() || 'not login', // 设置token为共享
    businessList: {},
    routeTitle: sessionStorage.getItem("routeTitle")
  },
  mutations: {
    // 设置token的mutations
    setToken (state, token) {
      state.token = token // 只是设置了vuex中的数据
      // 需要将vuex中的数据同步到缓存
      setToken(token)
    },
    removeToken (state) {
      state.token = null // 设置vuex中的token为null
      removeToken() // 同步删除缓存中的token
    },
    setList (state, reload) {
      state.businessList = reload
    },
    setTitle(state,reload) {
      sessionStorage.setItem("routeTitle",reload)
      state.routeTitle = reload
    }
  },
  actions: {
    // 封装一个登录的action
    // data认为是 { mobile,password }
    // 只要用async标记了函数 那么这个函数本身就是promise对象
    async login (context, data) {
      // 调用登录接口
      // login(data).then(result => {

      // })
      // await下方永远都是 reslove成功执行的逻辑
      const result = await login(data)
      // console.log('result', result)
      // result就是token
      context.commit('setToken', result.token)
      return result
    },
    // 获取用户资料
    // 登出action
    lgout ({ commit }) {
      // 删除token
      commit('removeToken')
      router.push('/login')
      // 删除用户资料
      // 重置路由
      // resetRouter()
      // 清空路由模块下的路由信息
      // Vuex子模块 调用子模块的mutation
      // commit('permission/setRoutes', [], { root: true }) // commit默认是提交的当前子模块的mutations
      // 如果加上 root: true 就表示commit此时是根级的commit
      // this.$store.commit('permission/setRoutes')
    }
  }
})
