/**
 * 工具类
 */
import CryptoJS from 'crypto-js'
let keyStr = "JXU2OPM3KXU1NkU0"
 
export function encrypt(word){ 
	keyStr = keyStr ? keyStr : 'abcdefgabcdefg12';
	var key  = CryptoJS.enc.Utf8.parse(keyStr);
	var srcs = CryptoJS.enc.Utf8.parse(word);
	var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode:CryptoJS.mode.ECB,padding: CryptoJS.pad.Pkcs7});
	return encrypted.toString();
}
 
export function decrypt(word) {
	try {
		keyStr = keyStr ? keyStr : "abcdefgabcdefg12";
		var key = CryptoJS.enc.Utf8.parse(keyStr); 
		var hexWordArray = CryptoJS.enc.Hex.parse(word);
		var base64Word = CryptoJS.enc.Base64.stringify(hexWordArray);
		var decrypt = CryptoJS.AES.decrypt(base64Word, key, {
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7,
		});
		return CryptoJS.enc.Utf8.stringify(decrypt).toString();
	}
	catch (e) {
		keyStr = keyStr ? keyStr : "abcdefgabcdefg12";
		var key = CryptoJS.enc.Utf8.parse(keyStr);
		var decrypt = CryptoJS.AES.decrypt(word, key, {
			mode: CryptoJS.mode.ECB,
			padding: CryptoJS.pad.Pkcs7,
		});
		return CryptoJS.enc.Utf8.stringify(decrypt).toString();
	}
}