<template>
  <div>
    <div>
        <p style="margin-bottom: 14px;line-height: 30px;">
        1、all环境只是用来存放所有工具的(以此为标准)，修改/删除all环境下的工具会同步影响其他环境（新增不会）<br>
        2、env下拉框只显示有app_info对应的环境（有在用的），其余的虽然在下拉框没显示但是在数据库（下方数据）中存在（无用的，可以不管）<br>
        3、删除则直接从数据库删除该环境下的该工具，工具的分类可以同时有多个。分类只针对工具，所以在全部环境都是一样的<br>
        4、新增工具的时候需要新增到all环境，才能新增到其他环境</p>
        <el-form :inline="true">
          <el-form-item>
            <el-select
              class="select_box"
              v-model="selectForm.env"
              placeholder="请选择env"
              clearable
              @focus="getEnvList"
            >
              <el-option
                v-for="(item, index) in envList"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              class="select_box"
              v-model="selectForm.classification"
              placeholder="请选择类型"
              @focus="getCateOptions"
              clearable
            >
              <el-option
                v-for="(item, index) in claOptions"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-select
              class="select_box"
              v-model="selectForm.name"
              placeholder="请选择name"
              @focus="getNameList"
              clearable
            >
              <el-option
                v-for="(item, index) in nameList"
                :key="index"
                :label="item"
                :value="item"
              >
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button
              class="submit_btn"
              @click="submit"
              type="primary"
              v-preventReclick="2500"
              >查询</el-button
            >
          </el-form-item>
        </el-form>

        <div>
          <el-button
            type="primary"
            class="add_btn"
            @click="editTool"
            v-preventReclick="2500"
            >新增工具</el-button
          >
          <el-button
              type="primary"
              class="add_btn"
              @click="hideInfo"
              v-preventReclick="2500">隐藏多余的信息以方便调整顺序</el-button>
        </div>
        <el-table :data="toolsList" border :loading="loading" stripe>
          <el-table-column prop="name" label="name" width="280" align="center">
            <template v-slot="{ row }">
              <div>
                <h4
                  class="truncate"
                  style="height: 20px; font-weight: bold; position: relative"
                >
                  <img :src="row.img" height="20px" />{{ row.title
                  }}<span
                    :class="{ bg_green: row.tag === '折扣' }"
                    class="tool_tag"
                    >{{ row.tag }}</span
                  >
                </h4>
              </div>
              <hr />
              <p class="table_p">{{ row.text }}</p>
              <p>{{ row.name }}</p>
              <div>
                <span class="original_price"
                  >原价：{{ row.original_price }}元/次 现价：</span
                ><span
                  class="sales_part"
                  style="color: rgb(255, 124, 57); font-weight: 600"
                  >{{ row.price }}元/次</span
                >
              </div>
            </template>
          </el-table-column>
        
          <el-table-column
            prop="env"
            label="env"
            align="center"
            width="80px"
          ></el-table-column>
          <el-table-column prop="form_data" label="form_data" width="200">
            <template v-slot="{ row }">
              <p v-for="(value, key) in row.form_data" :key="key">
                <el-tag type="success">{{ value.name }}</el-tag
                >{{ value.required ? "*" : "" }}{{ value.label }}({{
                  value.tip
                }})
              </p>
            </template>
          </el-table-column>
            <el-table-column label="分类" prop="classification" width="100px"></el-table-column>
          <el-table-column prop="introduce" label="introduce" align="center">
            <template v-slot="{ row }">
              <p v-for="(value, key) in row.introduce" :key="key">
                <el-tag type="success">{{ key }}</el-tag
                >{{ value }}
              </p>
              <p>
                <el-tag type="danger">tip</el-tag>{{row.tip}}
              </p>
              <p>
                <el-tag>create_time</el-tag>{{row.create_time}}
              </p>
              <p>
                <el-tag>update_time</el-tag>{{row.update_time}}
              </p>
            </template>
          </el-table-column>
          
          <!-- 修改工具的优先级 -->
          <el-table-column align="center" label="调节优先级" width="60px">
            <template v-slot="{ row }">
              <div class="pri_btn" @click="change_tool_priority('up', row)">
                <i class="el-icon-caret-top"></i>
              </div>
              <div class="pri_btn" @click="change_tool_priority('down', row)">
                <i class="el-icon-caret-bottom pri_btn"></i>
              </div>
            </template>
          </el-table-column>
          <!-- 修改工具优先级 -->
          <el-table-column align="center" label="操作" width="100px">
            <template v-slot="{ row }">
              <el-button type="text" @click="change_tool_cla(row)"
                >修改分类</el-button
              >
              <el-button type="text" @click="copyTool(row)">一键复制</el-button>
              <el-button type="text" @click="editTool(row, 1)">编辑</el-button>
              <el-button type="text" @click="deleteTool(row)"
                >删除工具</el-button
              >
            </template>
          </el-table-column>
        </el-table>

    </div>
    <!-- 编辑、添加弹出层 -->
    <el-dialog :close-on-click-modal="false" :title="title" :visible.sync="showDialog" @close="btnCancel" class="tool_dialog" width="800px">
      <el-form
        :model="form"
        label-width="100px"
        ref="addTool"
        label-position="left"
        :rules="rules"
      >
        <el-form-item label="env" prop="env" class="wd_350" >
          <el-select
            v-model="form.env"
            placeholder="请选择env"
            clearable
            @focus="getEnvList"
          >
            <el-option
              v-for="(item, index) in envList"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="name" prop="name">
          <el-input v-model="form.name" />
        </el-form-item>

        <el-form-item label="img" prop="img">
          <el-input v-model="form.img" />
        </el-form-item>
        <el-form-item label="original_price" prop="original_price">
          <el-input v-model="form.original_price" />
        </el-form-item>
        <el-form-item label="price" prop="price">
          <el-input v-model="form.price" />
        </el-form-item>
        <el-form-item label="text" prop="text">
          <el-input v-model="form.text" />
        </el-form-item>
        <el-form-item label="title" prop="title">
          <el-input v-model="form.title" />
        </el-form-item>
        <el-form-item label="tag" prop="tag">
          <el-input v-model="form.tag" />
        </el-form-item>
        <el-form-item label="form_data">
          <el-form
            v-for="(item, key, index) in form.form_data"
            :key="index"
            :inline="true"
            label-position="left"
            label-width="80px"
          >
            <el-form-item label="label">
              <el-input v-model="item.label" @input="forceUpdate"></el-input>
            </el-form-item>
            <el-form-item label="required">
              <el-switch v-model="item.required" @input="forceUpdate" />
            </el-form-item>
            <el-form-item label="name">
              <el-input v-model="item.name" @input="forceUpdate"></el-input>
            </el-form-item>
            <el-form-item label="tip">
              <el-input v-model="item.tip" @input="forceUpdate"> </el-input>
            </el-form-item>
            <el-form-item label="type">
              <el-select
                v-model="item.type"
                @select="forceUpdate"
                class="type_select"
              >
                <el-option label="input" value="input"></el-option>
                <el-option label="select" value="select"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item v-if="item.type === 'select'" style="display: inline">
              <el-button
                @click="addOption(item)"
                style="margin-bottom: 10px; margin-left: 20px"
                >添加option</el-button
              >
              <el-form
                v-for="(obj, index) in item.options"
                :key="index"
                label-position="left"
                inline
                label-width="55px"
              >
                <el-form-item label="value">
                  <el-input
                    v-model="obj.value"
                    class="option_input"
                    @input="forceUpdate"
                  ></el-input>
                </el-form-item>
                <el-form-item label="text">
                  <el-input
                    v-model="obj.text"
                    class="option_input"
                    @input="forceUpdate"
                  ></el-input>
                </el-form-item>
                <el-form-item>
                  <el-button
                    style="padding: 10px"
                    type="danger"
                    @click="del_option(item.options, index)"
                    >删除此option</el-button
                  >
                </el-form-item>
              </el-form>
            </el-form-item>
            <el-form-item>
              <el-form v-if="item.type === 'select'">
                <el-form-item
                  v-for="(option, index) in item.option"
                  :label="option.text"
                  :key="index"
                >
                  <el-input v-model="option.value"></el-input>
                </el-form-item>
              </el-form>
            </el-form-item>
            <el-form-item>
              <el-button
                icon="el-icon-delete"
                type="danger"
                @click="deleteForm_data(item, key)"
                >删除此项</el-button
              >
            </el-form-item>
            <hr />
          </el-form>
          <el-button
            icon="el-icon-circle-plus-outline"
            @click="addForm_dataItem"
            type="primary"
            v-preventReclick="1000"
            >添加form_data项</el-button
          >
        </el-form-item>
                <el-form-item label="introduce">
          <el-button
            icon="el-icon-circle-plus-outline"
            @click="addIntroduce"
            type="primary"
            v-preventReclick="2500"
            >添加介绍项</el-button
          >
          <p v-for="(item, index) in introduce" :key="index">
            <el-input v-model="item.label" class="intro_ipt"></el-input>
            <el-input v-model="item.content" class="intro_ipt"></el-input>
            <el-button
              icon="el-icon-delete"
              type="danger"
              @click="deleteIntroduce(index)"
              v-preventReclick="2500"
              >删除此项</el-button
            >
          </p>
        </el-form-item>
        <el-form-item label="tip">
          <el-input v-model="form.tip"></el-input>
        </el-form-item>
        <div style="text-align:center">
          <el-button type="primary" @click="btnOK" v-preventReclick="2500"
            >确定</el-button
          >
          <el-button @click="btnCancel" v-preventReclick="2500">取消</el-button>
        </div>
      </el-form>
    </el-dialog>
    <!-- 编辑、添加弹出层 -->
    <!-- </el-container> -->
    <!-- 添加、编辑工具分类的弹出层 -->
    <el-dialog
    :close-on-click-modal="false"
      :visible.sync="showToolCla"
      width="400px"
      @close="closeCla"
      title="修改工具分类"
    >
      <el-form label-position="left">
        <el-form-item>
          <el-select
            v-model="form.classification"
            multiple
            placeholder="请选择分类"
          >
            <el-option
              v-for="(item, index) in claOptions"
              :key="index"
              :label="item"
              :value="item"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="cla_add"
            placeholder="请输入分类用空格分隔"
          ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="confirm_cla">确定</el-button>
          <el-button @click="closeCla">取消</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>
    <!--/ 添加、编辑工具分类的弹出层 -->
  </div>
</template>

<script>
import {
  getToolsList,
  toolsListSelection,
  addTools,
  editTools,
  deleteTool,
  change_tool_priority,
  change_tool_cla,
} from "@/api/tool";
export default {
  created() {
    this.selectForm = this.$route.query;
    this.getToolsList();
  },
  data() {
    return {
      toolsList: [],
      nameList: [],
      envList: [],
      envValue: "",
      nameValue: "",
      showDialog: false,
      selectForm: {
        env: "",
        classification: "",
        name: "",
      },
      value: "input",
      form: {
        env: "",
        name: "",
        introduce: "",
        img: "",
        price: "",
        text: "",
        title: "",
        original_price: "",
        tag: "",
        form_data: {},
      },
      form_data: {},
      rules: {},
      type: 0,
      title: "",
      submitForm: {},
      introduce: [],
      loading: false,
      claOptions: [],
      showToolCla: false,
      cla_add: "",
    };
  },
  methods: {
    // 获取工具列表
    async getToolsList() {
      this.toolsList = await getToolsList(this.selectForm);
    },
    async getEnvList() {
      if (this.envList.length === 0) {
        this.envList = await toolsListSelection({
          select_type: "env",
        });
      }
    },
    async getNameList() {
      if (this.nameList.length === 0) {
        this.nameList = await toolsListSelection({
          select_type: "name",
        });
      }
    },
    async getCateOptions() {
      if (this.claOptions.length < 1) {
        this.claOptions = await toolsListSelection({
          select_type: "classification",
        });
      }
    },
    hideInfo() {
      var elements = document.getElementsByClassName('table_p');
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      var elements = document.getElementsByClassName('el-table_1_column_3');
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
      var elements = document.getElementsByClassName('el-table_1_column_5');
      while (elements.length > 0) {
        elements[0].parentNode.removeChild(elements[0]);
      }
    },
    // 添加、编辑工具
    editTool(list, type) {
      this.type = type;
      if (this.type === 1) {
        this.form = { ...list };
        this.title = "编辑工具";
        this.showDialog = true;
        let listIntroduce = list.introduce;
        let introduce = [];
        for (let i in listIntroduce) {
          introduce.push({
            label: i,
            content: listIntroduce[i],
          });
        }
        this.introduce = introduce;
      } else {
        this.showDialog = true;
        this.introduce = [
          {
            label: "",
            content: "",
          },
        ];
        this.title = "新增工具";
      }
    },
    async copyTool(list) {
      this.title = "一键复制";
      this.type = 0;
      this.showDialog = true;
      this.form = JSON.parse(JSON.stringify(list))
      let listIntroduce = list.introduce;
      let introduce = [];
      for (let i in listIntroduce) {
        introduce.push({
          label: i,
          content: listIntroduce[i],
        });
      }
      this.introduce = introduce;
    },
    // 当点击确定
    async btnOK() {
      let jsonIntrodcue = {};
      this.introduce.forEach((element) => {
        jsonIntrodcue[element["label"]] = element["content"];
      });
      this.form = {
        ...this.form,
        introduce: jsonIntrodcue,
      };
      if (this.type === 1) {
        await editTools(this.form);
      } else {
        await addTools(this.form);
      }
      await this.getToolsList();
      this.$forceUpdate();
      this.showDialog = false;
    },
    // 点击取消
    btnCancel() {
      this.showDialog = false;
      this.form = {
        env: "",
        id: "",
        create_time: "",
        introduce: "",
        form_data: "",
        img: "",
        name: "",
        price: "",
        text: "",
        title: "",
        original_price: "",
        tag: "",
      };
    },
    // 提交
    async submit() {
      this.$router.push(`/tools_list?env=${this.selectForm.env || ''}&classification=${this.selectForm.classification || ''}&name=${this.selectForm.name || ''}`)
      await this.getToolsList()
    },
    // 关闭添加新工具弹层的事件
    closeDialog() {
      this.addForm = {
        env: "",
        name: "",
        id: "",
        introduce: ["", "", "", ""],
        img: "",
        price: "",
        text: "",
        title: "",
        original_price: "",
        tag: "",
      };
      this.showAddDialog = false;
    },
    // 添加form_data项
    addForm_dataItem() {
      if (this.form.form_data.length === 0) {
        this.form.form_data = {
          0: {
            label: "",
            name: "",
            required: true,
            tip: "",
          },
        };
      } else {
        let count = 0;
        for (let i in this.form.form_data) {
          count++;
        }
        this.form.form_data[count] = {
          label: "",
          required: true,
          name: "",
          tip: "",
        };
      }
      this.$forceUpdate();
    },
    // 编辑时添加introduce
    addIntroduce() {
      this.introduce.push({});
    },
    //编辑室删除introduce
    deleteIntroduce(index) {
      this.introduce.splice(index, 1);
    },
    //编辑时删除formdate
    deleteForm_data(item, key) {
      delete this.form.form_data[key];
      this.$forceUpdate();
    },
    // 删除工具
    async deleteTool(tool) {
      this.$confirm("确定删除该工具吗，是否继续", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          this.loading = true;
          await deleteTool({
            id: tool.id,
          });
          await this.getToolsList();
          this.$forceUpdate();
        })
        .catch(() => {
          
        });
    },
    // 点击上移
    async change_tool_priority(direction, tool) {
      await change_tool_priority({
        env: tool.env,
        name: tool.name,
        action: direction,
      });
      await this.getToolsList();
      this.$forceUpdate();
    },
    forceUpdate() {
      this.$forceUpdate();
    },
    addOption(item) {
      if (!item.options) {
        item.options = [{ value: "", text: "" }];
      } else {
        item.options.push({
          value: "",
          text: "",
        });
      }
      this.$forceUpdate();
    },
    del_option(options, index) {
      options.splice(index, 1);
      this.$forceUpdate();
    },
    //秀给工具分类
    async change_tool_cla(tool) {
      this.form = JSON.parse(JSON.stringify(tool))
      this.getCateOptions();
      this.showToolCla = true;
    },
    //关闭修改分类的弹层
    closeCla() {
      this.showToolCla = false;
    },
    //确定修改分类
    async confirm_cla() {
      if (this.cla_add.trim()) {
        let strArr = this.cla_add.split(" ");
        this.form.classification = this.form.classification.concat(strArr);
      }
      await change_tool_cla({
        tool: this.form.name,
        classification_list: this.form.classification,
      });
      this.getToolsList()
      this.showToolCla = false;
    },
  },
};
</script>



<style>
.el-header {
  background-color: #b3c0d1;
  color: #333;
  line-height: 60px;
}
.el-dialog__header {
  background-color: #66b1ff;
  padding: 20px 20px 10px;
}
.el-dialog__title {
  color: #fff !important;
}
.el-aside {
  color: #333;
}
.select_box {
  padding: 10px;
}
.add_btn {
  float: left;
  margin-bottom: 30px;
}
.odd {
  width: 200px !important;
}
.even {
  margin-bottom: 10px;
}
.table_p {
  color: #888;
  overflow: hidden;
  /* height: 4.5rem; */
}
.el-message {
  top: 100px !important;
}
.pri_btn {
  cursor: pointer;
  display: block;
  font-size: 28px;
  margin-bottom: 20px;
}
.pri_btn :hover {
  color: #66b1ff;
}

.option_input {
  width: 150px !important;
}
.type_select .el-input--suffix .el-input__inner {
  padding-right: 15px;
}
.tool_tag {
  position: absolute;
  right: 0px;
  bottom: 0px;
  background-color: #ff7c39;
  padding: 0 6px;
  border-radius: 3px;
  color: #fff;
  font-size: 14px;
}
.bg_green {
  background-color: #5fb878 !important;
}
</style>