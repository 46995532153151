import router from "@/router";
export function isExternal(path) {
  return /^(https?:|mailto:|tel:)/.test(path)
}
function padLeftZero(str) {
  if (str < 10) {
    return 0 + str
  }
  return str;
}
export function formatDate(date, fmt) {
  if (!date) {
    return '';
  }
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length));
  }
  let o = {
    'M+': date.getMonth() + 1,
    'd+': date.getDate(),
    'h+': date.getHours(),
    'm+': date.getMinutes(),
    's+': date.getSeconds()
  };
  for (let k in o) {
    if (new RegExp(`(${k})`).test(fmt)) {
      let str = o[k] + '';
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : padLeftZero(str));
    }
  }
  return fmt;
}
export function format(point) {
  const formated = Number(point * 100).toFixed(2)
  const res = Math.ceil(formated)
  return `${res}%`
}
export function getDate(date) {
  //date是传过来的时间戳，注意需为13位，10位需*1000
  //也可以不传,获取的就是当前时间
  var time = new Date(date);
  var year = time.getFullYear()  //年
  var month = ("0" + (time.getMonth() + 1)).slice(-2); //月
  var day = ("0" + time.getDate()).slice(-2); //日
  var mydate = year + "-" + month + "-" + day;
  return mydate
}

export function isMobile() {
  if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {
    return true
  } else {
    return false
  }

}
export function debounce(fn, delay) {
  var timer
  return function (...args) {
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      fn.apply(this, args)
    }, delay)
  }
}
export function getNowTime() {
  var date = new Date();
  //年 getFullYear()：四位数字返回年份
  var year = date.getFullYear();  //getFullYear()代替getYear()
  //月 getMonth()：0 ~ 11
  var month = date.getMonth() + 1;
  //日 getDate()：(1 ~ 31)
  var day = date.getDate();
  //时 getHours()：(0 ~ 23)
  var hour = date.getHours();
  //分 getMinutes()： (0 ~ 59)
  var minute = date.getMinutes();
  //秒 getSeconds()：(0 ~ 59)
  var second = date.getSeconds();

  var time = year + '-' + addZero(month) + '-' + addZero(day) + ' ' + addZero(hour) + ':' + addZero(minute) + ':' + addZero(second);
  return time;
}


//小于10的拼接上0字符串
function addZero(s) {
  return s < 10 ? ('0' + s) : s;
}

//点击打开新页面
export function routeOpen(path, query = {}) {
  const { href } = router.resolve({
    path,
    query
  });
  window.open(href, '_blank');
}
// 排序
export function sortdatalist(propertyName) {
  var datalist = (object1, object2) => {
    var value1 = object1[propertyName]
    var value2 = object2[propertyName]
    if (value1 < value2) {
      return -1
    } else if (value1 > value2) {
      return 1
    } else {
      return 0
    }
  }
  return datalist
}
function getBase64Image(img) {
  var canvas = document.createElement("canvas");
  canvas.width = img.width;
  canvas.height = img.height;
  var ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0, img.width, img.height);
  var dataURL = canvas.toDataURL("image/png"); // 可选其他值 image/jpeg
  return dataURL;
}

export function toBase64(src, cb) {
  var image = new Image();
  image.src = src + '?v=' + Math.random(); // 处理缓存
  image.crossOrigin = "*"; // 支持跨域图片
  image.onload = function () {
    var base64 = getBase64Image(image);
    cb && cb(base64);
  }
}
// 对象转化为elementui树形结构
export function transformToTreeData(obj) {
  // 返回数组,数组的每个项每个项必须是一个对象，对象必须有一个label属性，children属性必须是一个数组
  const treeArr = [];
  if (typeof obj === "object") {
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        if (
          Object.prototype.toString.call(obj[key]) === "[object Array]"
        ) {
          const arr = obj[key];
          const children = [];
          for (let i = 0; i < arr.length; i++) {
            const arrItem = arr[i];

            if (typeof arrItem === "object") {
              children.push({
                label: i + 1,
                children: transformToTreeData(arrItem),
              });
            } else {
              children.push({
                label: arrItem,
              });
            }

          }
          const item = {
            label: key,
            children,
          };
          treeArr.push(item);
        } else if (
          Object.prototype.toString.call(obj[key]) === "[object Object]"
        ) {
          const item = {
            label: key,
            children: transformToTreeData(obj[key]),
          };
          treeArr.push(item);
        } else {
          const val = key + " : " + obj[key];
          treeArr.push({ label: val });
        }
      }
    }
  } else {
    treeArr.push({
      label: obj,
    });
  }
  return treeArr;
}
export function urlencode(inputStr) {
  var resultArr = [];
  var chars = " !\"#$ %& ' ()*+,/:;<=>?[]^`{|}~%"
  for (var i = 0; i < inputStr.length; i++) {
    var tmpChar = inputStr.charAt(i);
    var c = inputStr.charCodeAt(i);
    if (c > 0x7E) {
      resultArr[i] = encodeURI(tmpChar);
    } else {
      if (tmpChar == " ")
        resultArr[i] = "+";
      else if (chars.indexOf(tmpChar) != -1)
        resultArr[i] = "%" + c.toString(16);
      else
        resultArr[i] = tmpChar;
    }
  }
  return resultArr.join("");
}
export function urldecode(inputStr) {
  var resultArr = [];
  for (var i = 0; i < inputStr.length; i++) {
    var chr = inputStr.charAt(i);
    if (chr == "+") {
      resultArr[resultArr.length] = " ";
    } else if (chr == "%") {
      var asc = inputStr.substring(i + 1, i + 3);
      if (parseInt("0x" + asc) > 0x7f) {
        resultArr[resultArr.length] = decodeURI(inputStr.substring(i, i + 9));
        i += 8;
      } else {
        resultArr[resultArr.length] = String.fromCharCode(parseInt("0x" + asc));
        i += 2;
      }
    } else {
      resultArr[resultArr.length] = chr;
    }
  }
  return resultArr.join("");
}

export function switchDate(standardTime) {
  if (standardTime != "") {
    let a = standardTime;
    var Month = a.getMonth() + 1;
    var MonthDate;
    var DayDate = a.getDate();
    var Day;
    if (Month < 10) {
      MonthDate = '0' + Month;
    } else {
      MonthDate = Month;
    }
    if (DayDate < 10) {
      Day = '0' + DayDate;
    } else {
      Day = DayDate;
    }
    var datetime = a.getFullYear() + "-" + MonthDate + "-" + Day;
    console.log(datetime)
    return datetime
  }
}
export function getNowDate() {
  var date = new Date();
  var sign1 = "-";
  var sign2 = ":";
  var year = date.getFullYear(); // 年
  var month = date.getMonth() + 1; // 月
  var day = date.getDate(); // 日
  // 给一位数数据前面加 “0”
  if (month >= 1 && month <= 9) {
    month = "0" + month;
  }
  if (day >= 0 && day <= 9) {
    day = "0" + day;
  }
  var currentdate = year + sign1 + month + sign1 + day;
  return currentdate;
}
export function copyToClipboard(text) {
  var input = document.createElement("textarea");
  input.style.position = "fixed";
  input.style.opacity = 0;
  input.value = text;
  document.body.appendChild(input);
  input.select();
  document.execCommand("Copy");
  document.body.removeChild(input);
}
export function parseDay(dateStr) {
  const currentDate = new Date();
  const year1 = currentDate.getFullYear();
  const month1 = ("0" + (currentDate.getMonth() + 1)).slice(-2);
  const day1 = ("0" + currentDate.getDate()).slice(-2);
  const timestamp = Date.parse(dateStr);
  const date = new Date(timestamp);
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const day = date.getDate();
  if (!dateStr) {
    return `${year1}-${month1}-${day1}`;
  }
  return `${year}-${String(month).padStart(2, "0")}-${String(day).padStart(
    2,
    "0"
  )}`;
}
export function seven_days_ago() {
  //获取当前时间
  let today = new Date();

  //将当前时间向前推7天
  let sevenDaysAgo = new Date(today.setDate(today.getDate() - 7));

  //获取7天前的年月日
  let year = sevenDaysAgo.getFullYear();
  let month = sevenDaysAgo.getMonth() + 1;
  let day = sevenDaysAgo.getDate();

  //将月份和日期格式化为两位数
  if (month < 10) {
    month = '0' + month;
  }
  if (day < 10) {
    day = '0' + day;
  }
  return `${year}-${month}-${day}`

}

export function roundFloat(arr) {
  arr.forEach((obj) => {
    for (const property in obj) {
      if (typeof obj[property] === 'number' && !Number.isInteger(obj[property])) {
        obj[property] = parseFloat(obj[property].toFixed(2));
      }
    }
  });
  return arr;
}

