import request from '@/utils/request'
// 封装登录请求的接口
export const login  = (ruleForm) => {
    return request({
      url:'/login/login',
      method: 'POST',
      data: {
        account: ruleForm.account,
        password: ruleForm.password
      },
      params: {
        code: ruleForm.code,
        code_id: ruleForm.code_id
      }
    })
  }
  // 封装获取验证码的接口
  export const get_img_code = () => {
    return request({
      url:'/login/img_code',
      method: 'GET',
    })
  }
  // 封装登录检查的接口
  export const login_check = () => {
    return request({
      url: "/login_check",
      method: "GET"
    })
  }
