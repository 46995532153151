import Vue from 'vue'
import VueRouter from 'vue-router'
import ToolsList from '@/views/tools/ToolsList.vue'
import Layout from '@/layout/LayoutIndex.vue'
import store from '../store'
// import store from '@/store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/schema',
    name: 'test',
    component: () => import('@/TestIndex')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/login/LoginIndex'),
    meta: {
      title: '登录'
    }
  }, {
    path: '/',
    component: Layout,
    redirect: '/dashboard',
    children: [{
      path: 'dashboard',
      name: 'dashboard',
      component: () => import('@/views/dashboard/dashBoard'),
      meta: { title: '首页' },
    },
    {
      path: 'ana',
      name: 'ana',
      component: () => import('@/views/dashboard/analysisAnalyze'),
      meta: { title: '数据分析' },
    },
    
    ]
  }, 
    {
      path: '',
      name: 'haoma',
      component: Layout,
      children: [
        {
          path: 'biaoji_ana',
          name: 'BiaojiAna',
          component: () => import('@/views/dashboard/biaojiAnalysis'),
          meta: { title: '号码标记数据分析' },
        },
        {
          path: 'biaoji_user',
          name: 'BiaojiUser',
          component: () => import('@/views/haoma/UserList'),
          meta: {
            title: '号码标记渠道记录'
          }
        },
        {
          path: 'biaoji_phone',
          name: 'BiaojiPhone',
          component: () => import('@/views/haoma/PhoneList'),
          meta: {
            title: '号码标记号码记录'
          }
        }
      ]
    },
  {
    path: '',
    name: 'tool',
    component: Layout,
    children: [
      {
        path: '/tools',
        component: () => import('@/views/tools/ToolsIndex'),
        name: 'tools',
        meta: {
          title: '工具列表',
        }
      },
      {
        path: '/tools_list',
        component: ToolsList,
        name: 'tools_list',
        meta: {
          title: '工具列表',
        }
      }, {
        path: '/tools_history',
        component: () => import('@/views/tools/ToolsUseHistory'),
        name: 'tools_history',
        meta: {
          title: '工具使用历史',
        }
      }, {
        path: '/tools_env',
        component: () => import('@/views/tools/ToolsEnv'),
        name: 'tools_env',
        meta: {
          title: '工具环境'
        }
      }, {
        path: '/tools_analysis',
        component: () => import('@/views/tools/ToolsAnalysis'),
        name: 'tools_analysis',
        meta: {
          title: '工具数据',
        }
      },
      {
        path: '/tools_change_cla',
        component: () => import('@/views/tools/ChangeClaPriority'),
        name: 'tools_change_cla',
        meta: {
          title: '修改分类优先级',
        }
      },
    ]
  }, {
    path: '',
    name: 'user',
    component: Layout,
    children: [
      {
        path: '/user_list',
        component: () => import('@/views/user/UserList'),
        name: 'user_list',
        meta: {
          title: '用户列表',
        }
      }, {
        path: '/user_detail',
        component: () => import('@/views/user/UserDetail'),
        name: 'user_detail',
        meta: {
          title: '用户token详情',
        }
      }, {
        path: '/blackUser_list',
        component: () => import('@/views/user/BlackUserList.vue'),
        name: 'blackUser_list',
        meta: {
          title: '黑名单列表'
        }
      }, {
        path: '/whiteUser_list',
        component: () => import('@/views/user/WhiteUserList.vue'),
        name: 'whiteUser_list',
        meta: {
          title: '白名单列表'
        }
      }, {
        path: '/user_analysis',
        component: () => import('@/views/user/UserAnalysis.vue'),
        name: 'user_analysis',
        meta: {
          title: '用户数据列表'
        }
      },
      {
        path: '/user_ipDetail',
        component: () => import('@/views/user/IpDetail.vue'),
        name: 'user_ipDetail',
        props: true,
        meta: {
          title: '用户ip详情'
        }
      }
    ],
  },
  {
    path: '',
    name: 'order',
    component: Layout,
    children: [
      {
        path: '/order_list',
        component: () => import('@/views/order/OrderList.vue'),
        name: 'order_list',
        meta: {
          title: '订单列表'
        }
      },
      {
        path: '/order_analysis',
        component: () => import('@/views/order/OrderAnalysis.vue'),
        name: 'order_analysis',
        meta: {
          title: '订单数据'
        }
      },
      {
        path: '/order_info',
        component: () => import('@/views/order/OrderInfo.vue'),
        name: 'order_info',
        meta: {
          title: '订单详情'
        }
      },
    ]
  }, {
    path: '',
    name: 'feedback',
    component: Layout,
    children: [
      {
        path: '/user_feedback',
        component: () => import('@/views/feedback/FeedbackUser.vue'),
        name: 'user_feedback',
        meta: {
          title: '用户反馈列表'
        }
      },
      {
        path: '/user_complaint',
        component: () => import('@/views/feedback/UserComplaint.vue'),
        name: 'user_complaint',
        meta: {
          title: '用户投诉列表'
        }
      }
    ]
  },
  {
    path: '',
    name: 'this_project_config',
    component: Layout,
    children: [
      {
        path: '/systemAdmin',
        component: () => import('@/views/system/SystemAdmin.vue'),
        name: 'systemAdmin',
        meta: {
          title: '系统管理'
        }
      }, {
        path: '/projectAdmin',
        component: () => import('@/views/system/projectAdmin.vue'),
        name: 'projectAdmin',
        meta: {
          title: '项目配置'
        }
      }]
  },
  {
    path: '',
    name: 'general_config',
    component: Layout,
    children: [
       {
        path: '/general_config_json',
        component: () => import('@/views/general_config/JsonManage.vue'),
        name: 'general_config_json',
        meta: {
          title: 'json配置管理'
        }
      },  {
        path: '/sample_setting',
        component: () => import('@/views/general_config/sample_setting.vue'),
        name: 'sample_setting',
        meta: {
          title: 'simple通用配置'
        }
      }]
  },{
    path: '',
    name: 'function',
    component: Layout,
    children: [
      {
        path: '/page_function',
        component: () => import('@/views/function/FunctionHandle.vue'),
        name: 'page_function',
        meta: {
          title: '功能管理'
        }
      },
      {
        path: '/page_problem',
        component: () => import('@/views/function/CommonProblem.vue'),
        name: 'page_problem',
        meta: {
          title: '常见问题'
        }
      },
      {
        path: '/page_urlScheme',
        component: () => import('@/views/function/urlScheme'),
        name: 'page_urlScheme',
        meta: {
          title: 'urlScheme管理'
        }
      },
      {
        path: '/page_swiper',
        component: () => import('@/views/function/pageSwiper'),
        name: 'page_swiper',
        meta: {
          title: '轮播图管理'
        }
      },
      {
        path: '/page_pay_way',
        component: () => import('@/views/function/pagePayway'),
        name: 'page_pay_way',
        meta: {
          title: '付款方式管理'
        }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [{
      path: '/daily_data',
      component: () => import('@/views/revenue/DailyData.vue'),
      name: 'daily_data',
      meta: {
        title: '收支情况'
      }
    }, {
      path: '/daily_data_relationship',
      component: () => import('@/views/revenue/DailyDataRelationship.vue'),
      name: 'daily_data_relationship',
      meta: {
        title: '各推广关系收支情况'
      }
      }, {
        path: '/income_relationship',
        component: () => import('@/views/revenue/IncomeRelationship.vue'),
        name: 'income_relationship',
        meta: {
          title: '推广关系管理'
        }
      }, {
        path: '/queryword_report',
        component: () => import('@/views/revenue/AdQuerywordReport.vue'),
      name: 'queryword_report',
        meta: {
          title: '搜索词报告'
        }
      },
      {
        path: '/keyword_report',
        component: () => import('@/views/revenue/AdKeywordReport.vue'),
        name: 'keyword_report',
        meta: {
          title: '关键词报告'
        }
      }, {
        path: '/get_operate_log',
        component: () => import('@/views/revenue/AdReportOperateLog.vue'),
        name: 'get_operate_log',
        meta: {
          title: '广告报告操作记录'
        }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    meta: {
      title: '推广服务管理'
    },
    children: [
      {
        path: '/account_balance',
        component: () => import('@/views/popularize/accountBalance.vue'),
        name: 'account_balance',
        meta: {
          title: '查看第三方账号余额'
        }
      }]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/chat_rule',
        component: () => import('@/views/chat/ChatRule.vue'),
        name: 'chat_rule',
        meta: {
          title: '聊天服务管理'
        }
      }, {
        path: '/chat_page',
        component: () => import('@/views/chat/ChatPage.vue'),
        name: 'chat_page',
        meta: {
          title: '聊天页面'
        }
      }
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/file_manage',
        component: () => import('@/views/file/FileManage.vue'),
        name: 'file_manage',
        meta: {
          title: '文件管理'
        }
      },
      {
        path: '/short_link',
        component: () => import('@/views/file/ShortLink.vue'),
        name: 'short_link',
        meta: {
          title: '短链接管理'
        }
      },
      {
        path: '/message_promotion',
        component: () => import('@/views/file/MessagePromotion.vue'),
        name: 'message_promotion',
        meta: {
          title: '短信推广管理'
        }
      },
    ]
  },
  {
    path: '',
    component: Layout,
    children: [
      {
        path: '/test',
        component: () => import('@/views/test.vue'),
        meta: {
          title: '测试'
        }
      },
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (store.state.token == 'not login' && to.path != '/login') {
    router.push('login')
  }
  if (to.params.title) {
    store.commit("setTitle",to.params.title)
  }
  if (to.meta.title !== '信息通查' && to.meta.description) {
    document.title = `${to.meta.title}-${to.meta.description}-信息通查`
  } else if (to.meta.description === undefined) {
    document.title = `${to.meta.title}`
  } else {
    document.title = `${to.meta.title}-${to.meta.description}`
  }

  next()
})
router.afterEach(() => {
  window.scrollTo(0, 0);
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
export default router
