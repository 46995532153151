import axios from 'axios'
import store from '@/store'
import { Message } from 'element-ui'
import { Loading } from 'element-ui'
import router from '@/router'
import _ from 'lodash'
let loadingInstance;

// 请求合并只出现一次loading
// 当前正在请求的数量
let loadingRequestCount = 0;
const showLoading = () => {
  if(loadingRequestCount === 0) {
    loadingInstance = Loading.service({
      lock: true,
      text: '加载中...',
      background:'rgba(0,0,0,0)'
    });
  }
  loadingRequestCount++;
}

// 隐藏loading的函数，并且记录请求次数
const hideLoading = () => {
  if(loadingRequestCount <= 0) return;
  loadingRequestCount--;
  if(loadingRequestCount === 0) {
    toHideLoading();
  }
}

// 防抖：将 300ms 间隔内的关闭 loading 便合并为一次. 防止连续请求时, loading闪烁的问题。
var toHideLoading = _.debounce(() => {
  loadingInstance.close()
}, 200);

const request = axios.create({
  baseURL: '/admin',
  headers:{
    'oc-app':'h5:dnadmin:v1.0'
  }
})
// 请求拦截器
request.interceptors.request.use(function (config) {
  if(!config.notLoading) {
    showLoading()
  }
  if (store.state.token !== 'not login') {
    config.headers['oc-token'] = store.state.token
  }else{
    router.push('/login')
  }
  return config
}, function (error) {
  // 如果请求出错了（还没有发出去）会进入这里
  return Promise.reject(error)
}
)

// 响应拦截器
request.interceptors.response.use(response => {
  hideLoading()
  const { msg, data, tip } = response.data
  if (msg === 'success') {
    if (tip.length > 1) {
      Message.success(tip)
    }
    return data
  } else {
    hideLoading()
    Message.error(tip)
    if (tip === '认证已过期，请重新登录' || tip === '请登录' || tip === '账号密码错误' || tip === '账号或密码错误' ) {
      store.dispatch('lgout')
    }
    return Promise.reject(new Error(tip))
  }
}, error => {
  hideLoading()
  Message.error('未知错误')
  return Promise.reject(error)
})

export default request
