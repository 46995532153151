<template>
  <div class="menu_container">
    <el-menu
      style="margin-top: 50px"
      :collapse="isCollapse"
      :unique-opened="true"
    >
      <el-submenu index="1" class="title">
        <template slot="title"
          ><i class="el-icon-s-home" @click="$router.push('/')"></i
          >首页</template
        >
        <el-menu-item-group>
          <el-menu-item
            index="1-1"
            @click="
              $router.push({ name: 'ana', params: { title: '数据分析' } })
            "
            >数据分析</el-menu-item
          >
          <el-menu-item
              index="1-2"
              @click="
                $router.push({ name: 'account_balance', params: { title: '首页' } })
                "
              >第三方账号余额</el-menu-item
            >
            <el-menu-item
              index="9-2"
              @click="
                $router.push({
                  name: 'chat_page',
                  params: { title: '首页' },
                })
                "
              >智能客服</el-menu-item
            >
          </el-menu-item-group>
          
      </el-submenu>
      <el-submenu index="11" class="title">
        <template slot="title"
          ><i class="el-icon-eleme"></i
          >号码标记</template
        >
        <el-menu-item-group>
          <el-menu-item
            index="11-1"
            @click="
              $router.push({ name: 'BiaojiAna', params: { title: '号码标记' } })
            "
            >数据分析</el-menu-item
          >
          <el-menu-item
            index="11-2"
            @click="
              $router.push({ name: 'BiaojiUser', params: { title: '号码标记' } })
            "
            >渠道记录</el-menu-item
          >
          <el-menu-item
            index="11-3"
            @click="
              $router.push({ name: 'BiaojiPhone', params: { title: '号码标记' } })
            "
            >号码记录</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="2">
        <template slot="title"><i class="el-icon-user"></i>用户管理</template>
        <el-menu-item-group>
          <el-menu-item
            index="2-1"
            @click="
              $router.push({ name: 'user_list', params: { title: '用户管理' } })
            "
            >用户列表</el-menu-item
          >
          <el-menu-item
            index="2-2"
            @click="
              $router.push({
                name: 'blackUser_list',
                params: { title: '用户管理' },
              })
            "
            >黑名单列表</el-menu-item
          >
          <el-menu-item
            index="2-3"
            @click="
              $router.push({
                name: 'whiteUser_list',
                params: { title: '用户管理' },
              })
            "
            >白名单列表</el-menu-item
          >

          <el-menu-item
            index="2-6"
            @click="
              $router.push({
                name: 'user_analysis',
                params: { title: '用户管理' },
              })
            "
            >用户数据</el-menu-item
          >
          <el-menu-item
              index="2-7"
              @click="
                $router.push({
                  name: 'user_feedback',
                  params: { title: '用户管理' },
                })
                "
              >用户反馈列表</el-menu-item
            >
            <el-menu-item
              index="2-8"
              @click="
                $router.push({
                  name: 'user_complaint',
                  params: { title: '用户管理' },
                })
                "
              >用户投诉列表</el-menu-item
            >
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="3">
        <template slot="title"
          ><i class="el-icon-s-order"></i>订单管理</template
        >
        <el-menu-item-group>
          <el-menu-item
            index="3-1"
            @click="
              $router.push({
                name: 'order_list',
                params: { title: '订单管理' },
              })
            "
            >订单列表</el-menu-item
          >
          <el-menu-item
            index="3-3"
            @click="
              $router.push({
                name: 'order_analysis',
                params: { title: '订单管理' },
              })
            "
            >订单数据</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="4">
        <template slot="title"
          ><i class="el-icon-s-tools"></i>工具管理</template
        >
        <el-menu-item-group>
          <el-menu-item
            index="4-1"
            @click="
              $router.push({ name: 'tools', params: { title: '工具管理' } })
            "
            >工具列表大纲</el-menu-item
          >
          <el-menu-item
            index="4-6"
            @click="
              $router.push({
                name: 'tools_list',
                params: { title: '工具管理' },
              })
            "
            >工具列表</el-menu-item
          >
          <el-menu-item
            index="4-2"
            @click="
              $router.push({
                name: 'tools_history',
                params: { title: '工具管理' },
              })
            "
            >工具使用历史记录</el-menu-item
          >
          <el-menu-item
            index="4-3"
            @click="
              $router.push({ name: 'tools_env', params: { title: '工具管理' } })
            "
            >环境对应管理</el-menu-item
          >
          <el-menu-item
            index="4-4"
            @click="
              $router.push({
                name: 'tools_analysis',
                params: { title: '工具管理' },
              })
            "
            >数据详情</el-menu-item
          >
          <el-menu-item
            index="4-5"
            @click="
              $router.push({
                name: 'tools_change_cla',
                params: { title: '工具管理' },
              })
            "
            >分类管理</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="5">
        <template slot="title"><i class="el-icon-menu"></i>数据配置</template>
        <el-menu-item-group>
          <el-menu-item
            index="5-1"
            @click="
              $router.push({
                name: 'systemAdmin',
                params: { title: '数据配置' },
              })
            "
            >登录历史</el-menu-item
          >
          <el-menu-item
            index="5-3"
            @click="
              $router.push({
                name: 'projectAdmin',
                params: { title: '数据配置' },
              })
            "
            >json项目配置</el-menu-item
          >
          <el-menu-item
              index="5-4"
              @click="
                $router.push({
                  name: 'page_pay_way',
                  params: { title: '数据配置' },
                })
                "
              >付款方式配置</el-menu-item
            >
            <el-menu-item
              index="5-5"
              @click="
                $router.push({
                  name: 'page_problem',
                  params: { title: '数据配置' },
                })
                "
              >常见问题管理</el-menu-item
            >
            <el-menu-item
              index="9-1"
              @click="
                $router.push({
                  name: 'chat_rule',
                  params: { title: '数据配置' },
                })
                "
              >智能客服回复规则</el-menu-item
            >
        </el-menu-item-group>
      </el-submenu>
      
      <el-submenu index="7">
        <template slot="title"
          ><i class="el-icon-document"></i>废弃</template
        >
        <el-menu-item-group>
          <el-menu-item
            index="7-1"
            @click="
              $router.push({
                name: 'page_function',
                params: { title: '页面配置' },
              })
            "
            >页面功能管理</el-menu-item
          >

          <el-menu-item
            index="7-3"
            @click="
              $router.push({
                name: 'page_urlScheme',
                params: { title: '页面配置' },
              })
            "
            >urlScheme配置</el-menu-item
          >
          <el-menu-item
            index="7-4"
            @click="
              $router.push({
                name: 'page_swiper',
                params: { title: '页面配置' },
              })
            "
            >轮播图配置</el-menu-item
          >

        </el-menu-item-group>
      </el-submenu>
      <el-submenu index="8">
        <template slot="title"
          ><img src="../../assets/钱.svg" class="img" />营收管理</template
        >
        <el-menu-item-group>
          <el-menu-item
            index="8-2"
            @click="
              $router.push({
                name: 'daily_data',
                params: { title: '营收管理' },
              })
            "
            >收支情况</el-menu-item
          >
          <el-menu-item
            index="8-3"
            @click="
              $router.push({
                name: 'daily_data_relationship',
                params: { title: '营收管理' },
              })
            "
            >各推广关系收支情况</el-menu-item
          >
          <el-menu-item
            index="8-4"
            @click="
              $router.push({
                name: 'income_relationship',
                params: { title: '营收管理' },
              })
            "
            >推广关系管理</el-menu-item
          >
          <el-menu-item index="8-5" @click=" $router.push({name: 'queryword_report', params: { title: '营收管理' },})">
            搜索词报告
            </el-menu-item>
          <el-menu-item index="8-6" @click=" $router.push({name: 'keyword_report', params: { title: '营收管理' },})">
            关键词报告
            </el-menu-item>
          <el-menu-item index="8-7" @click=" $router.push({name: 'get_operate_log', params: { title: '营收管理' },})">
            广告报告操作记录
            </el-menu-item>
        </el-menu-item-group>
      </el-submenu>
      
      <el-submenu index="10">
        <template slot="title"><i class="el-icon-files"></i>管理端通用配置</template>
        <el-menu-item-group>
          <el-menu-item
            index="10-02"
            @click="
              $router.push({
                name: 'sample_setting',
                params: { title: 'simple通用配置' },
              })
            "
            >simple通用配置</el-menu-item
          >
          <el-menu-item
            index="10-01"
            @click="
              $router.push({
                name: 'general_config_json',
                params: { title: 'json通用配置' },
              })
            "
            >json通用配置</el-menu-item
          >

          <el-menu-item
            index="10-1"
            @click="
              $router.push({
                name: 'file_manage',
                params: { title: '其他' },
              })
            "
            >文件管理</el-menu-item
          >
          <el-menu-item
            index="10-2"
            @click="
              $router.push({
                name: 'short_link',
                params: { title: '其他' },
              })
            "
            >短链接管理</el-menu-item
          >
          <el-menu-item
            index="10-3"
            @click="
              $router.push({
                name: 'message_promotion',
                params: { title: '其他' },
              })
            "
            >短信推广管理</el-menu-item
          >
        </el-menu-item-group>
      </el-submenu>
      <div>
        <div style="text-align: center" class="ban">v1.2.7</div>
      </div>
      <div class="fl_cen">
        <div class="fold_btn" @click="toggleSidbar">
          <i class="el-icon-s-fold icon_fold pointer"></i>
        </div>
      </div>
    </el-menu>
  </div>
</template>

<script>
export default {
  props: {
    isCollapse: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  methods: {
    toggleSidbar() {
      this.$emit("toggleSidbar");
    },
  },
};
</script>
<style>
.el-submenu .el-menu-item {
  min-width: unset;
}
.el-menu {
  position: relative;
  border-right: 0;
}
.el-menu::-webkit-scrollbar {
  display: none;
}
.img {
  margin-right: 8px;
  padding-left: 5px;
  width: 16px;
}
.el-icon-fold {
  font-size: 18px;
}

.el-submenu__title {
  padding: 13px 20px 13px 24px;
  color: rgba(48, 49, 51, 1);
}
.el-submenu__icon-arrow {
  margin-top: -3px;
}
itle i {
  color: rgba(48, 49, 51, 1);
}
.el-menu-item.is-active {
  color: rgba(64, 158, 255, 1);
}
.fold_btn {
  right: 6px;
  width: 36px;
  height: 32px;
  border-radius: 4px;
  background: rgba(245, 247, 250, 1);
  display: flex;
  justify-content: center;
  align-items: center;
}
.wd40 .fl_cen {
  margin-top: 10px;
  margin-right: 14px;
}
.wd40 .ban {
  display: none;
}
</style>